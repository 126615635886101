<template>
  <div v-if="planDetaiObj !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl  w-full">
      <div class="flex justify-center items-center">
        <h1 class="heading-2 text-center">Subscription</h1>
      </div>
    </div>
    <div class="flex justify-center" >
        <div class=" sm:w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
            <div class=" text-gray3 mb-4  card rounded-xl bg-white p-4 my-4">
              <div class="text-text2 heading-2 font-semibold">{{planDetaiObj.currentPlanName}}</div>
              <div class="sm:grid grid-cols-2">
                <div class="pt-2">
                  <div class="text-gray4 heading-5">{{planDetaiObj.totalUsedStorage | getFileSize}} of {{planDetaiObj.currentPlanStorage}} GB used</div>
                  <!-- <div class="text-gray4 heading-5"  v-if="!planOverError && !planTodayExpire && !planSomeDaysError && !showStorageErr">Expire day: {{ this.planDetaiObj.currentPlanEndOn | dateFilterInvoiceRegularFormate}} (in {{ diffDays }} days)</div> -->
                </div>
                <div class="text-primary heading-4 text-right" >
                  <span class="cursor-pointer" @click="$router.push({name: 'SubscriptionStorage'})">Manage Storage</span>
                </div>
              </div>
              <div class="text-error pt-2">
                Your {{planDetaiObj.currentPlanName}} will expire in {{ diffDays }} days, please consider upgrading your plant to countinue with all benefits.
              </div>
              <div class="pt-2">
                <div class="heading-5 text-error" v-if="!planOverError && planTodayExpire && !planSomeDaysError" >Your {{this.planDetaiObj.isFree ? 'Trial is about ' : this.planDetaiObj.currentPlanName + ' plan Has'}} expire.please <router-link :to="{ name: 'plan' , query: {currentPlanId: this.planDetaiObj.planId}} " target="_blank" class="cursor-pointer text-blue-400 hover:underline">click here</router-link> to upgrade your plan to continue</div>
                <div class="heading-5 text-error" v-if="!planOverError && !planTodayExpire && planSomeDaysError" >Your {{this.planDetaiObj.isFree ? 'free trial ' : this.planDetaiObj.currentPlanName + ' plan '}} will expires in {{this.diffDays}} days, please consider upgrading your plan to countinue with all benefits</div>
                <div :class="`${showStorageErr && (planTodayExpire || planSomeDaysError) ? '-bottom-5' : showStorageErr && (!planTodayExpire && !planSomeDaysError) ? '-bottom-1' : ''}`" class="absolute -bottom-1 pl-2 heading-6 text-error" v-if="showStorageErr" >*You have utilized 90% of your {{this.planDetaiObj.currentPlanStorage}} GB space of your current plan, please upgrade your plan or manage your space by logging into mobile app to continue</div>
              </div>
              <div class="flex justify-center pt-4">
                <Button
                  :btnSize="'medium'"
                  :textColor="'white'"
                  :btnColor="'primary'"
                  :btnText="`Subscribe Now`"
                  @buttonAction="redirectToSubscriptionScreen()"
                />
              </div>
            </div>
        </div>
    </div>
    <!-- <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure?'" /> -->
  </div>
</template>
<script>
import axios from 'axios'
import MyJobApp from "@/api/MyJobApp.js";
import Button from "@/View/components/globalButton.vue";
export default {
  name: "customer",
  components: {
    Button
  },
  data() {
    return {
      showStorageErr: false,
      planSomeDaysError: false,
      planTodayExpire: false,
      planOverError: false,
      diffDays: 0,
      planDetaiObj: null,
      userAndCompDetail: null,
      removeConfirm: false,
      activeUer: {},
      cCode: ''
    };
  },
  created() {},
  mounted() {
    this.activeUer = JSON.parse(localStorage.getItem('jobUserInfo'))
    this.currentPlanDetail()
    axios.get(`https://ipapi.co/json/`).then(data => {
      if (data.status === 200) {
        console.log('data', data)
        this.cCode = data.data.country
      }
    })
  },
  methods: {
    redirectToSubscriptionScreen () {
      let url =  this.$router.resolve({ name: 'plan', query: {currentPlanId: this.planDetaiObj.planId, currencyCode: this.cCode}});
      window.open(url.href, '_blank')
    },
    currentPlanDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.GetCurrentPlanDetail(
        response => {
          this.planDetaiObj = response.Data !== null ? response.Data : null
          const requestDt = new Date(this.planDetaiObj.currentPlanEndOn).setHours(0, 0, 0)
          const todayDate = new Date().setHours(0, 0, 0)
          console.log('requestDt--->()', requestDt, this.planDetaiObj.currentPlanEndOn)
          console.log('todayDate--->()', todayDate, new Date())
          const diffTime = Math.abs(todayDate - requestDt);
          this.diffDays = Math.ceil(parseFloat((diffTime / (1000 * 3600 * 24)).toFixed(2)));
          console.log('diffDays', this.diffDays)
          if (this.diffDays === 0) {
            this.planOverError = false
            this.planTodayExpire = true
            this.planSomeDaysError = false
            this.$root.$emit('disableAllscreen', false, this.planDetaiObj)
          } else {
            if (requestDt < todayDate) {
              this.planOverError = true
              this.planTodayExpire = false
              this.planSomeDaysError = false
              this.$root.$emit('disableAllscreen', true, this.planDetaiObj)
            } else if (this.diffDays < 30) {
                this.planOverError = false
                this.planTodayExpire = false
                this.planSomeDaysError = true
                this.$root.$emit('disableAllscreen', false, this.planDetaiObj)
              // this.showExpireErr = 
              }
          }
          let totalStorage = parseInt(this.planDetaiObj.currentPlanStorage)*1024
          let fileSizeMB = this.planDetaiObj.totalUsedStorage / (1024 ** 2)
          let totalUsedStorage = fileSizeMB.toFixed(2)
          const totalUsedPers = ((100 * totalUsedStorage) / totalStorage)
          if (totalUsedPers >= 90) {
            this.showStorageErr = true
          } else {
            this.showStorageErr = false
          }
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        () => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    },
  },
  beforeDestroy() {
    this.$root.$off("EditAddressHandler");
    this.$root.$off("closeEditPopup");
    this.$root.$off("editQIAddressHandler");
  },
};
</script>
<style scoped>
.table_col {
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
.name_col {
    min-width: 200px;
    max-block-size: 200px;
}
.des_col {
    min-width: 250px;
    max-block-size: 250px;
}
.pric_col {
    min-width: 100px;
    max-block-size: 100px;
}
.qnty_col {
    min-width: 100px;
    max-block-size: 100px;
}
.amt_col {
    min-width: 100px;
    max-block-size: 100px;
}
</style>